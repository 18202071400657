import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import About from "components/About";
import Layout from "components/Layout";
import ProjectCard from "components/ProjectCard";
import Profile from "components/_ui/illustrations/Profile";
import ContactCTA from "components/_ui/ContactCTA";

const Hero = styled("div")`
    padding-top: 2.5em;
    margin-bottom: 6em;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
       margin-bottom: 3em;
    }

    h1 {
        margin-bottom: 1em;
        text-align: center;
    }
`

const Section = styled("div")`
    margin-bottom: 10em;
    display: flex;
    flex-direction: column;

    @media(max-width: ${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        align-items: center;
     }

    &:last-of-type {
        margin-bottom: 0;
    }
`

const WorkTitle = styled("h1")`
    margin-bottom: 1em;

    @media(max-width: ${dimensions.maxwidthTablet}px) {
        text-align: center;
    }
`

const HeroContent = styled("div")`
    display: flex;
    justify-content: center;
    margin-bottom: 4em;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        flex-direction: column;
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            text-align: center;
            padding: 0;
        }
    }

    li {
        font-size: 1.5rem;
        list-style: none;

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            font-size: 1.25rem;
        }
    }

    svg {
        width: 20%;

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            margin: 0 auto;
            width: 30%;
        }
    }
`

const RenderBody = ({ home, projects, meta }) => (
    <>
        <Helmet
            title={meta.title}
            titleTemplate={`%s | ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: meta.title,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: meta.title,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
            ].concat(meta)}
        />
        <Hero>
            {RichText.render(home.hero_title)}
            <HeroContent>
                <Profile />
                {RichText.render(home.hero_content)}
            </HeroContent>
        </Hero>
        <ContactCTA />
        <Section>
            <WorkTitle>
                Work
            </WorkTitle>
            {projects.map((project, i) => (
                <ProjectCard
                    key={i}
                    category={project.node.project_category}
                    title={project.node.project_title}
                    description={project.node.project_preview_description}
                    thumbnail={project.node.project_preview_thumbnail}
                    uid={project.node._meta.uid}
                />
            ))}
        </Section>
        <Section>
            {RichText.render(home.about_title)}
            <About
                bio={home.about_bio}
                socialLinks={home.about_links}
            />
        </Section>
    </>
);

export default ({ data }) => {
    //Required check for no data being returned
    const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    const projects = data.prismic.allProjects.edges;
    const meta = data.site.siteMetadata;

    if (!doc || !projects) return null;

    return (
        <Layout>
            <RenderBody home={doc.node} projects={projects} meta={meta} />
        </Layout>
    )
}

RenderBody.propTypes = {
    home: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    {
        prismic {
            allHomepages {
                edges {
                    node {
                        hero_title
                        hero_content
                        about_title
                        about_bio
                        about_links {
                            about_link
                        }
                    }
                }
            }
            allProjects {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`